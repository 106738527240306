import React, { useState, useEffect } from "react";

const TimeCountDown = ({ setIsGameClose, targetTime, setIsLoading }) => {
  const parseTime = (timeString) => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return { hours, minutes };
  };

  const calculateTimeUntilNextReset = () => {
    const now = new Date();
    const { hours, minutes } = parseTime(targetTime);
    const target = new Date(now);
    target.setHours(hours, minutes, 0, 0); // Set target time based on parsed hours and minutes

    if (now > target) {
      target.setDate(target.getDate() + 1);
    }

    return target.getTime() - now.getTime();
  };

  const [countDown, setCountDown] = useState(calculateTimeUntilNextReset());
  const [isAfterTargetTime, setIsAfterTargetTime] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const { hours, minutes } = parseTime(targetTime);
      const target = new Date(now);
      target.setHours(hours, minutes, 0, 0); // Set target time based on parsed hours and minutes

      if (now >= target) {
        setIsAfterTargetTime(true);
        setIsGameClose(true);
        setIsLoading && setIsLoading(false);
        clearInterval(interval);
      } else {
        setCountDown(calculateTimeUntilNextReset());
        setIsAfterTargetTime(false);
        setIsGameClose(false);
        setIsLoading && setIsLoading(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown, targetTime]);

  // useEffect(()=>{
  //   setIsLoading(false)
  // },[])

  const hours = Math.floor((countDown / (1000 * 60 * 60)) % 24)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((countDown / (1000 * 60)) % 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((countDown / 1000) % 60)
    .toString()
    .padStart(2, "0");

  if (isAfterTargetTime) {
    return "Closed";
  } else {
    return `${hours}:${minutes}:${seconds}`;
  }
};

export default TimeCountDown;
