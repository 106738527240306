import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import { API_KEY } from '../utils/constants';
import { contactUsAPI } from '../api/api';
import Loader from '../components/loader';

function ContactUs() {
  const [mobileNumber, setMobileNumber] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [telegram, setTelegram] = useState('');
  const [email, setEmail] = useState('');
  const [youtube, setYoutube] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const getFundData = async () => {
    const formData = new FormData();
    formData.append('__api_key__', API_KEY);

    await contactUsAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          setIsLoading(false)
          setMobileNumber(res.data.data.meta.contact_number);
          setWhatsapp(res.data.data.meta.whatsapp_number);
          setTelegram(res.data.data.meta.telegram_number);
          setEmail(res.data.data.meta.email);
          setYoutube(res.data.data.meta.youtube_link);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getFundData();
  }, []);

  return (
    <div className='main-2 bg-4'>
      <Header title='Contact Us' isMenu />
      <div className='container mt-5'>
        <div className='d-flex align-items-center my-3'>
          <img src='./images/call.png' style={{width: 50, height: 50}} alt='call' />
          <div className='ms-3'>
            <h6>Mobile Number</h6>
            <h6 style={{color: '#6c62ff'}}>{mobileNumber}</h6>
          </div>
        </div>
        <div className='d-flex align-items-center my-3'>
          <img src='./images/whatsapp.png' style={{width: 50, height: 50}} alt='whatsapp' />
          <div className='ms-3'>
            <h6>WhatsApp</h6>
            <h6 style={{color: '#6c62ff'}} onClick={()=>window.open(`https://wa.me/${whatsapp}`, '_blank')}>{whatsapp}</h6>
          </div>
        </div>
        <div className='d-flex align-items-center my-3'>
          <img src='./images/telegram.png' style={{width: 50, height: 50}} alt='telegram' />
          <div className='ms-3'>
            <h6>Telegram Channel</h6>
            <h6 style={{color: '#6c62ff'}} onClick={()=>window.open(`https://t.me/${telegram}`, '_blank')}>{telegram}</h6>
          </div>
        </div>
        <div className='d-flex align-items-center my-3'>
          <img src='./images/mail.png' style={{width: 50, height: 50}} alt='mail' />
          <div className='ms-3'>
            <h6>Email</h6>
            <h6 style={{color: '#6c62ff'}} onClick={()=> window.open(`mailto:${email}`, '_blank')}>{email}</h6>
          </div>
        </div>
        <div className='d-flex justify-content-end my-3'>
          <img src='./images/youtube.png' style={{width: 80, height: 80}} alt='youtube' onClick={()=>window.open(youtube, '_blank')} />
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default ContactUs;
