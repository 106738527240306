import React, { useEffect, useRef, useState } from "react";
import Header from "../components/header";
import { API_KEY } from "../utils/constants";
import { teerGameResultAPI } from "../api/api";
import Loader from "../components/loader";
import { toast } from "react-toastify";

function KhanaparaResult() {
  const [khanaparaData, setKhanaparaData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchStatus, setSearchStatus] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const resultRefs = useRef([]);

  const dateFormat = (dateStr) => {
    const [day, month, year] = dateStr.split("-");
    return new Date(`${year}-${month}-${day}`);
  };

  const getHistory = async () => {
    const formData = new FormData();
    formData.append("__api_key__", API_KEY);

    await teerGameResultAPI(formData)
      .then((res) => {
        if (res.data.state === "OK") {
          const sortData = res.data.data.teer_results.sort(
            (a, b) =>
              (b.date !== "" && dateFormat(b.date)) -
              (a.date !== "" && dateFormat(a.date))
          );
          transformData(sortData.filter((item) => item.date !== ""));
        }
      })
      .catch((error) => console.log(error));
  };

  const transformData = (data) => {
    const getMonthYear = (dateStr) => {
      const [day, month, year] = dateStr.split("-");
      const date = new Date(`${year}-${month}-${day}`);
      return date.toLocaleString("default", { month: "long", year: "numeric" });
    };

    const groupedData = data.reduce((acc, item) => {
      const monthYear = getMonthYear(item.date);
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(item);
      return acc;
    }, {});

    const teerData = Object.keys(groupedData).map((month) => ({
      month,
      data: groupedData[month],
    }));
    setIsLoading(false);
    setKhanaparaData(teerData);
  };

  useEffect(() => {
    getHistory();
  }, []);

  useEffect(() => {
    if (searchStatus) {
      const results = [];
      khanaparaData.forEach((monthData) => {
        monthData.data.forEach((item, index) => {
          if (
            item.khanapara_fr === searchText ||
            item.khanapara_sr === searchText
          ) {
            results.push({ monthData, item, index });
          }
        });
      });
      setSearchResults(results);
      setCurrentIndex(0);
    }
  }, [searchStatus, searchText, khanaparaData]);

  useEffect(() => {
    if (searchResults.length > 0 && resultRefs.current[currentIndex]) {
      resultRefs.current[currentIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentIndex, searchResults]);

  const handleSearchNext = () => {
    if (currentIndex < searchResults.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleSearchPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="main-2">
      <div className="col-12" style={{ position: "fixed", maxWidth: 450 }}>
        <Header title={"Khanapara Previous Results"} isMenu bgColor noWallet />
        <div className="col-12 search-div px-4">
          <div className="position-relative">
            <input
              className="form-control"
              name="search"
              placeholder="Search any number"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />

            {!searchStatus ? (
              <div style={{ position: "absolute", bottom: 15, right: 10 }}>
                <i
                  className="bi bi-search"
                  onClick={() => {
                    searchText.length === 0
                      ? toast.error("please enter text")
                      : setSearchStatus(true);
                  }}
                />
              </div>
            ) : (
              <div style={{ position: "absolute", bottom: 5, right: 10 }}>
                <div className="d-flex align-items-center">
                  <i
                    className="bi bi-chevron-up"
                    style={{ fontSize: 25 }}
                    onClick={handleSearchPrev}
                  />
                  <i
                    className="bi bi-chevron-down mx-2"
                    style={{ fontSize: 25 }}
                    onClick={handleSearchNext}
                  />
                  <i
                    className="bi bi-x"
                    style={{ cursor: "pointer", fontSize: 30 }}
                    onClick={() => setSearchStatus(false)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: 110 }}>
        {khanaparaData.map((monthData, monthIndex) => (
          <div className="text-center" key={monthIndex}>
            <h5 className="mt-4">{monthData.month}</h5>
            <div className="d-flex justify-content-around py-3">
              <h6 className="col-6">DATE</h6>
              <h6 className="col-3">FR</h6>
              <h6 className="col-3">SR</h6>
            </div>
            <div>
              {monthData.data.map((content, index) => {
                const isCurrentResult =
                  searchResults[currentIndex]?.item === content;
                return (
                  <div
                    className="d-flex justify-content-around border-bottom-1 border-bottom py-1"
                    key={index}
                    ref={(el) => {
                      if (isCurrentResult) {
                        resultRefs.current[currentIndex] = el;
                      }
                    }}
                  >
                    <label className="col-6 py-2">{content.date}</label>
                    <label
                      className={`col-3 py-2 ${
                        searchStatus &&
                        content.khanapara_fr === searchText &&
                        "bg-danger"
                      }`}
                    >
                      {content.khanapara_fr}
                    </label>
                    <label
                      className={`col-3 py-2 ${
                        searchStatus &&
                        content.khanapara_sr === searchText &&
                        "bg-danger"
                      }`}
                    >
                      {content.khanapara_sr}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default KhanaparaResult;
