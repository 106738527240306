import React, { useEffect, useRef, useState } from "react";
import Header from "../components/header";
import GameCard from "../components/gameCard";
import { API_KEY } from "../utils/constants";
import { gameListAPI, sliderImageAPI, teerGameHistoryAPI } from "../api/api";
import ImageSlider from "../components/imageSlider";
import { useNavigate } from "react-router-dom";
import HistoryCard from "../components/historyCard";
import Loader from "../components/loader";

function History() {
  const navigate = useNavigate();
  const [gameList, setGameList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const endDateInputRef = useRef(null);
  const startDateInputRef = useRef(null);

  const handleEndDate = () => {
    if (endDateInputRef.current) {
      endDateInputRef.current.showPicker();
    }
  };

  const handleStartDate = () => {
    if (startDateInputRef.current) {
      startDateInputRef.current.showPicker();
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(new Date(event.target.value));
  }

  const handleDateChange = (event) => {
    setEndDate(new Date(event.target.value));
  };

  const getHistory = async () => {
    setIsLoading(true)
    const userData = await localStorage.getItem('user');
    const user_uid = JSON.parse(userData).uid;

    const formData = new FormData();
    formData.append("__api_key__", API_KEY);
     formData.append("user_uid", user_uid);
    formData.append("starting_year", startDate.getFullYear());
    formData.append("starting_month", startDate.getMonth()+1);
    formData.append("starting_date", startDate.getDate());
    formData.append("ending_year", endDate.getFullYear());
    formData.append("ending_month", endDate.getMonth()+1);
    formData.append("ending_date", endDate.getDate());
   
    await teerGameHistoryAPI(formData)
      .then((res) => {
        if (res.data.state === "OK") {
          const sortData = res.data.data.histories.reverse()
          setGameList(sortData);
          setIsLoading(false)
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="main-1 bg-1">
      <div className="container text-center ">
        <Header isMenu title={"History"} />
        <div className="d-flex justify-content-around">
          <div
            className="col-5 bg-white shadow-sm rounded-4 p-2 mt-4"
            style={{ color: "#6c62ff" }}
            onClick={handleStartDate}
          >
            <h6>From Date</h6>
            <div
              className="w-100 mb-2"
              style={{ border: "1px solid #6c62ff" }}
            />
            <h5 className="fw-bold">{startDate.getDate()}</h5>
            <h5 className="fw-bold">
              {startDate
                .toLocaleString("default", { month: "short" })
                .toUpperCase()}
            </h5>
            <h5 className="fw-bold">{startDate.getFullYear()}</h5>
          </div>

          <input
            type="date"
            ref={startDateInputRef}
            className="d-none"
            onChange={handleStartDateChange}
          />
          <div
            className="col-5 bg-white shadow-sm rounded-4 p-2 mt-4"
            style={{ color: "#6c62ff" }}
            onClick={handleEndDate}
          >
            <h6>To Date</h6>
            <div
              className="w-100 mb-2"
              style={{ border: "1px solid #6c62ff" }}
            />
            <h5 className="fw-bold">{endDate.getDate()}</h5>
            <h5 className="fw-bold">
              {endDate
                .toLocaleString("default", { month: "short" })
                .toUpperCase()}
            </h5>
            <h5 className="fw-bold">{endDate.getFullYear()}</h5>
          </div>

          <input
            type="date"
            ref={endDateInputRef}
            className="d-none"
            onChange={handleDateChange}
          />
        </div>
        <div className="col-8 mx-auto mt-4">
          <button
            className="btn bg-white w-100 rounded-5 fw-bold fs-5"
            style={{ color: "#6c62ff" }}
            onClick={() => getHistory()}
          >
            SUBMIT
          </button>
        </div>
        <div className="game-list-container">
          {gameList.map((item) => (
            <HistoryCard item={item} />
          ))}
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default History;
