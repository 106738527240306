import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { API_KEY } from "../utils/constants";
import { contactUsAPI, playGameAPI } from "../api/api";
import { useLocation, useNavigate } from "react-router-dom";

function HowToUse() {
  const [data, setData] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const formData = new FormData();
    formData.append("__api_key__", API_KEY);

    await contactUsAPI(formData)
      .then((res) => {
        if (res.data.state === "OK") {
          setData(res.data.data.meta);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="main-2">
      <Header title="How To Use" bgColor isMenu />
      <div className="container d-flex flex-column justify-content-between align-items-center" style={{height: '90vh'}}>
        <div>
          <div className="text-center">
            <img
              src="./images/one_teer_logo.jpeg"
              className="img-fluid w-50"
              alt="logo"
            />
          </div>
          <p style={{color: '#6c62ff'}}>{data.description}</p>
        </div>
        <div className="col-10">
          <button className="play-button btn rounded-5">
            WATCH ON YOUTUBE
          </button>
        </div>
      </div>
    </div>
  );
}

export default HowToUse;
