import React, { useState, useEffect } from 'react';

function ForgotTimer({setTimer, timer}) {
  const [countDown, setCountDown] = useState(timer);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1000);
        setTimer(countDown - 1000)
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  const minutes = Math.floor((countDown / (1000 * 60)) % 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor((countDown / 1000) % 60)
    .toString()
    .padStart(2, '0');

  return (
    <div>
    <p
      style={{
        fontSize: 14,
        marginTop: 15,
        color: 'black',
        fontWeight: '600',
      }}
    >
    {minutes} : {seconds}
    </p>
  </div>
  );
}

export default ForgotTimer;
