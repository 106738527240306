import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import { API_KEY } from '../utils/constants';
import { userDataAPI } from '../api/api';
import Loader from '../components/loader';

function Profile() {
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const getUserData = async () => {
    const userData = await localStorage.getItem('user');
    const user_uid = JSON.parse(userData)?.uid;

    const formData = new FormData();
    formData.append('__api_key__', API_KEY);
    formData.append('user_uid', user_uid);
    await userDataAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          setUser(res.data.data.user_entity);
          setIsLoading(false)
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div className='main-2 bg-1'>
      <Header title='Edit Profile' isMenu  />
      <div className='container mx-auto'>
        <div className='text-center mt-4'>
          <img
            src='./images/avatar.png'
            style={{ width: 140, height: 140, borderRadius: 140, backgroundColor: '#fff', padding: 5 }}
            alt='profile'
          />
        </div>
        <div className='w-100 bg-white shadow-sm rounded-4 p-3 mt-3 text-start'>
          <h6>User Name</h6>
          <text>
            <i class='bi bi-person-fill me-2' />
            {user.username}
          </text>
        </div>
        <div className='w-100 bg-white shadow-sm rounded-4 p-3 mt-3 text-start'>
          <h6>Email Address</h6>
          <text>
            <i class='bi bi-envelope me-2' />
            {user.email == '' ? 'Username@gmail.com' : user.email}
          </text>
        </div>
        <div className='w-100 bg-white shadow-sm rounded-4 p-3 mt-3 text-start'>
          <h6>Phone Number</h6>
          <text>
            <i class='bi bi-phone me-2' />
            {user.phone}
          </text>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default Profile;
