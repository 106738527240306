import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import { API_KEY } from '../utils/constants';
import { gameRateAPI } from '../api/api';
import Loader from '../components/loader';

function GameRate() {
  const [gameRateData, setGameRateData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getFundData = async () => {
    const formData = new FormData();
    formData.append('__api_key__', API_KEY);

    await gameRateAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          setIsLoading(false)
          setGameRateData(res.data.data.game_rates);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getFundData();
  }, []);

  return (
    <div className='main-2 bg-4'>
      <Header title='Rates' isMenu />
      <div className='container mt-5'>
        {gameRateData.map(item => (
          <div className='w-100 border rounded-5 my-3 text-center p-2'>
          <h6>{item.name}</h6>
          <h6 className='m-0' style={{color: '#6c62ff'}}>10 = {item.price}</h6>
        </div>
        ))}
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default GameRate;
