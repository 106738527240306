import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { API_KEY } from "../utils/constants";
import { contactUsAPI } from "../api/api";

function AboutUs() {
  const [data, setData] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const formData = new FormData();
    formData.append("__api_key__", API_KEY);

    await contactUsAPI(formData)
      .then((res) => {
        if (res.data.state === "OK") {
          setData(res.data.data.meta);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="main-2 bg-4">
      <Header title="About Us" isMenu />
      <div className="container mt-5" >
        <div>
          
          <p style={{color: '#6c62ff'}}>{data.description}</p>
        </div>
        
      </div>
    </div>
  );
}

export default AboutUs;
