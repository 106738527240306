import React, { useEffect, useState } from "react";
import { API_KEY } from "../utils/constants";
import { createOTPAPI, loginAPI } from "../api/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { generateOTP } from "../utils/generateOTP";
import Loader from "../components/loader";
import OTPInput from "../components/otpInput";
import { numberValidation } from "../utils/validation";
import ForgotTimer from "../components/forgotTimer";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [createdOTP, setCreateOTP] = useState("");
  const [timer, setTimer] = useState(0);


  const handleOtpChange = (val) => {
    setOtp(val);
  };

  const handleVerify = async () => {
    if (otp == createdOTP) {
      toast.success("OTP verify successfully");
      navigate("/reset-password", {state: { mobile_number: email }});
    } else {
      toast.error("Wrong OTP");
    }
  };

  const handleSentOtp = async () => {
    if (email.length == 0) {
      toast.error("Enter Your Phone Number");
      return;
    } else {
      const otp = generateOTP();
      setCreateOTP(otp);
      setIsLoading(true);
      const formData = new FormData();
      formData.append("__api_key__", API_KEY);
      formData.append("mobile_number", email);
      formData.append("otp", otp);
      formData.append("request_id", "1234");

      await createOTPAPI(formData)
        .then((res) => {
          if (res.data.state === "OK") {
            toast.success("OTP sent to your mobile number");
            setIsLoading(false);
            setTimer(120000)
            setIsOTP(true);
          }
        })
        .catch((error) => {console.log(error); setIsLoading(false)});
    }
  };

  useEffect(() => {
    async function fetchData() {
      const userData = await localStorage.getItem("user");
      if (userData != null) {
        navigate("/home");
      }
    }
    fetchData();
  }, []);

  return (
    <div className="main login">
      <div className="container text-center">
        <img
          src="./images/laptop.png"
          className="img-fluid"
          alt="reset password"
        />
        <h5 className="heading">Reset Password</h5>
        <div className="text-center mt-2">
          <h6 className="mb-3">Enter Phone Number to get OTP</h6>
          <div className="text-start mt-4 bg-white rounded-4 py-2 px-2 position-relative">
          <input
            name="email"
            placeholder="9999999999"
            value={email}
            onChange={(e) => {numberValidation.test(e.target.value) && setEmail(e.target.value)}}
          />
          <div style={{ position: "absolute", left: 10, bottom: 9 }} className='d-flex align-items-center'>
            <i class="bi bi-phone fs-4 text-secondary" />
            <span className="mx-1">
              +91
            </span>
          </div>
        </div>
        </div>
        {timer == 0 ? <button
          type="button"
          className="btn login mt-3 rounded-5 shadow w-50"
          onClick={() => handleSentOtp()}
        >
          GET OTP
        </button> : <ForgotTimer setTimer={setTimer} timer={timer} />}
        {isOTP && (
          <div>
            <div className="text-center mt-5">
              <h6 className="mb-3">Enter OTP</h6>
              <OTPInput length={6} onChange={handleOtpChange} />
            </div>

            <button
              type="button"
              className="form-control login mt-5 rounded-5 shadow"
              onClick={() => handleVerify()}
            >
              CONFIRM
            </button>
          </div>
        )}
        <div className="d-flex justify-content-center align-items-center mt-4">
          <h6 className="mb-0 forget" onClick={() => navigate("/")}>
            Log In
          </h6>
          <h6 className=" mb-0 forget mx-3">|</h6>
          <h6 className="mb-0 forget" onClick={() => navigate("/signup")}>
            Sign Up
          </h6>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default ForgotPassword;
