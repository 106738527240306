import React, { useEffect, useState } from "react";
import { API_KEY } from "../utils/constants";
import { resetPasswordAPI } from "../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/loader";

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const { mobile_number } = location.state || {};
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(true);
  const [showPass1, setShowPass1] = useState(true);

  const handleLogin = async () => {
    if (password.length == 0) {
      toast.error("Enter a password");
      return;
    }
    if (confirmPassword.length == 0) {
      toast.error("Enter a password again");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Password did not match");
      return;
    } else {
      setIsLoading(true)
      const formData = new FormData();
      formData.append("__api_key__", API_KEY);
      formData.append("phone_number", `+91${mobile_number}`);
      formData.append("password", password);

      await resetPasswordAPI(formData)
        .then((res) => {
          setIsLoading(false)
          if (res.data.state === "OK") {
            toast.success('Password changed successfully')
            navigate("/");
          } else{
            toast.error('No user found with mobile number')
          }
        })
        .catch((error) => {console.log(error); setIsLoading(false)});
    }
  };

  useEffect(() => {
    async function fetchData() {
      const userData = await localStorage.getItem("user");
      if (userData != null) {
        navigate("/home");
      }
    }
    fetchData();
  }, []);

  return (
    <div className="main login">
      <div className="container text-center">
        <img
          src="./images/laptop.png"
          className="img-fluid"
          alt="reset password"
        />
        <h5 className="heading">Reset Password</h5>
        <div className="text-start mt-3 bg-white rounded-4 py-1 px-2 position-relative">
          <label>New Password</label>
          <input
            name="password"
            placeholder="............"
            type={showPass?'password': 'text'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div style={{ position: "absolute", left: 10, bottom: 5 }}>
            <i class="bi bi-lock fs-4 text-secondary" />
          </div>
          <div style={{ position: "absolute", right: 10, bottom: 5 }}>
            <i class="bi bi-eye-fill fs-4" onClick={()=>setShowPass(!showPass)}></i>
          </div>
        </div>
        <div className="text-start mt-3 bg-white rounded-4 py-1 px-2 position-relative">
          <label>Re Enter Password</label>
          <input
            name="password"
            placeholder="............"
            type={showPass1?'password': 'text'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div style={{ position: "absolute", left: 10, bottom: 5 }}>
            <i class="bi bi-lock fs-4 text-secondary" />
          </div>
          <div style={{ position: "absolute", right: 10, bottom: 5 }}>
            <i class="bi bi-eye-fill fs-4" onClick={()=>setShowPass1(!showPass1)}></i>
          </div>
        </div>
        {/* <div className="text-center mt-2">
          <label>New Password</label>
          <input
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="text-center mt-2">
          <label>Re Enter Password</label>
          <input
            className="form-control"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div> */}
        <button
          type="button"
          className="form-control login mt-3 rounded-5 shadow"
          onClick={() => handleLogin()}
        >
          RESET
        </button>
        <div className="d-flex justify-content-center align-items-center mt-4">
          <h6 className="mb-0 forget" onClick={() => navigate("/")}>
            Log In
          </h6>
          <h6 className=" mb-0 forget mx-3">|</h6>
          <h6 className="mb-0 forget" onClick={() => navigate("/signup")}>
            Sign Up
          </h6>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default ResetPassword;
