import React from "react";

const BothSidehistoryCard = ({ item }) => {
  return (
    <div className="bg-white rounded-3 p-3 shadow mt-2">
      <h5>{item.game_name}</h5>
      <h6>{item.game_type}</h6>
      <div className="flex-row d-flex justify-content-between">
        <label className="col-3">Left Digits</label>
        <label className="col-3">Right Digits</label>
        <label className="col-3">Amount</label>
        <label className="col-3">Total</label>
      </div>
        <div className="flex-row d-flex justify-content-between">
          <div className="flex-column d-flex col-3 align-items-center">
          {item.left_digit_data.digits.map((digit)=>(
            <label>{digit}</label>
          ))}
          </div>
          <div className="flex-column d-flex col-3 align-items-center ">
          {item.right_digit_data.digits.map((digit)=>(
            <label className="col-3">{digit}</label>
          ))}
          </div>
          <label className="col-3">{item.amount}</label>
          <label className="col-3">{item.total}</label>
        </div>
    </div>
  );
};

export default BothSidehistoryCard;
