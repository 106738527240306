import React, { useEffect, useState } from 'react';
import { API_KEY } from '../utils/constants';
import { createOTPAPI, signUpAPI } from '../api/api';
import { generateOTP } from '../utils/generateOTP';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { numberValidation } from '../utils/validation';
import Loader from '../components/loader';

function SignUp() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleSignUp = async () => {
    if(name.length == 0){
      toast.error('Enter Username')
    }else if(phone.length == 0){
      toast.error('Enter Phone Number')
    } else if(password.length == 0){ 
      toast.error('Enter Password')
    } else{
    const otp = generateOTP();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('__api_key__', API_KEY);
    formData.append('mobile_number', phone);
    formData.append('otp', otp);
    formData.append('request_id', '1234');

    const signupData= {
      user_name: name,
      email: email,
      phone: `+91${phone}`,
      password:password,
      createdOtp: otp
    }

    await createOTPAPI(formData)
      .then((res) => {
        setIsLoading(false);
        if (res.data.state === 'OK') {
          toast.success('OTP sent to your mobile number')
          navigate('/otp-verify',{state: signupData})
        }
      })
      .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    async function fetchData() {
      const userData = await localStorage.getItem("user");
      if (userData != null) {
        navigate("/home");
      }
    }
    fetchData();
  }, []);

  return (
    <div className='main login'>
      <div className='container text-center'>
        <h5 className='heading'>Sign Up</h5>
        <div className="text-start mt-4 bg-white rounded-4 py-1 px-2 position-relative">
          <label>User Name</label>
          <input
            name="text"
            placeholder="User Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div style={{ position: "absolute", left: 10, bottom: 5 }}>
            <i class="bi bi-person-fill fs-4 text-secondary" />
          </div>
        </div>

        <div className="text-start mt-4 bg-white rounded-4 py-1 px-2 position-relative">
          <label>Email Address</label>
          <input
            name="text"
            placeholder="username@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div style={{ position: "absolute", left: 10, bottom: 5 }}>
            <i class="bi bi-envelope fs-4 text-secondary" />
          </div>
        </div>
        <div className="text-start mt-4 bg-white rounded-4 py-1 px-2 position-relative">
          <label>Phone Number</label>
          <input
            name="email"
            placeholder="9999999999"
            value={phone}
            onChange={(e) => {numberValidation.test(e.target.value) && setPhone(e.target.value)}}
          />
          <div style={{ position: "absolute", left: 10, bottom: 5 }} className='d-flex align-items-center'>
            <i class="bi bi-phone fs-4 text-secondary" />
            <span className="mx-1">
              +91
            </span>
          </div>
        </div>
        <div className="text-start mt-3 bg-white rounded-4 py-1 px-2 position-relative">
          <label>Password</label>
          <input
            name="password"
            placeholder="............"
            type={showPass?'password': 'text'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div style={{ position: "absolute", left: 10, bottom: 5 }}>
            <i class="bi bi-lock fs-4 text-secondary" />
          </div>
          <div style={{ position: "absolute", right: 10, bottom: 5 }}>
            <i class="bi bi-eye-fill fs-4" onClick={()=>setShowPass(!showPass)}></i>
          </div>
        </div>
        

        <button
          type='button'
          className='form-control login mt-5 rounded-5 shadow'
          onClick={() => handleSignUp()}
        >
          Create
        </button>
        <h6 className='mt-4 mb-0 text-secondary'>
          Already have an account ! <span className='forget' onClick={()=>navigate('/')}>Log in</span>
        </h6>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default SignUp;
