import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import { API_KEY } from '../utils/constants';
import { getBankDetailsAPI, updateBankDetailsAPI } from '../api/api';
import { toast } from 'react-toastify';
import Loader from '../components/loader';

function Bank() {
  const [bankName, setBankName] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankIFSCCode, setBankIFSCCode] = useState('');
  const [paytmNumber, setPaytmNumber] = useState('');
  const [phonePeNumber, setPhonePeNumber] = useState('');
  const [googlePayNumber, setGooglePayNumber] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = async () => {
    setIsLoading(true)
    const userData = await localStorage.getItem('user');
    const user_uid = JSON.parse(userData).uid;

    // if (Number(amount) < 999 || Number(amount) > 49000) {
    //   toast.error('You can withdraw in between 999 to 49000');
    //   return;
    // } else if (Number(wallet) < Number(amount)) {
    //   toast.error('Insufficient funds!');
    //   return;
    // } else {
    const formData = new FormData();
    formData.append('__api_key__', API_KEY);
    formData.append('user_uid', user_uid);
    formData.append('bank_name', bankName);
    formData.append('bank_account_holder_name', accountHolderName);
    formData.append('bank_account_number', accountNumber);
    formData.append('bank_ifsc_code', bankIFSCCode);
    formData.append('paytm_number', paytmNumber);
    formData.append('phonepe_uid', phonePeNumber);
    formData.append('googlepay_uid', googlePayNumber);

    await updateBankDetailsAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          toast.success('Updated bank detail successfully');
          setIsLoading(false)
        }
      })
      .catch((error) => console.log(error));
    // }
  };

  const getBankData = async () => {
    const userData = await localStorage.getItem('user');
    const user_uid = JSON.parse(userData).uid;

    const formData = new FormData();
    formData.append('__api_key__', API_KEY);
    formData.append('user_uid', user_uid);
    await getBankDetailsAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          setIsLoading(false)
          const bankData = res.data.data.bank_detail
          setBankName(bankData.bank_name);
          setAccountHolderName(bankData.account_holder_name);
          setAccountNumber(bankData.account_number);
          setBankIFSCCode(bankData.ifsc_code);
          setPaytmNumber(bankData.payment_number);
          setPhonePeNumber(bankData.phonepe_uid);
          setGooglePayNumber(bankData.google_pay_uid);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getBankData();
  }, []);

  return (
    <div className='main-2' style={{ backgroundColor: '#6c62ff' }}>
      <Header title='Bank Details' isBack />
      <div className='container mt-3 d-flex flex-column align-items-center'>
        <div className='w-100 bg-white shadow-sm rounded-4 p-3 mt-3'>
          <h6 style={{ color: '#6c62ff' }}>Bank Name</h6>
          <input
            className='form-control mt-3'
            placeholder='Bank Name'
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
          />
        </div>
        <div className='w-100 bg-white shadow-sm rounded-4 p-3 mt-3'>
          <h6 style={{ color: '#6c62ff' }}>Account Holder Name</h6>
          <input
            className='form-control mt-3'
            placeholder='Account Holder Name'
            value={accountHolderName}
            onChange={(e) => setAccountHolderName(e.target.value)}
          />
        </div>
        <div className='w-100 bg-white shadow-sm rounded-4 p-3 mt-3'>
          <h6 style={{ color: '#6c62ff' }}>Account Number</h6>
          <input
            className='form-control mt-3'
            placeholder='Account Number'
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </div>
        <div className='w-100 bg-white shadow-sm rounded-4 p-3 mt-3'>
          <h6 style={{ color: '#6c62ff' }}>Bank IFSC Code</h6>
          <input
            className='form-control mt-3'
            placeholder='Bank IFSC Code'
            value={bankIFSCCode}
            onChange={(e) => setBankIFSCCode(e.target.value)}
          />
        </div>
        <h6 className='text-white fw-bold text-center mt-4'>Accounts</h6>
        <div className='w-100 bg-white shadow-sm rounded-4 p-3 mt-3'>
          <h6 style={{ color: '#6c62ff' }}>Paytm Number</h6>
          <input
            className='form-control mt-3'
            placeholder='Payment Number'
            value={paytmNumber}
            onChange={(e) => setPaytmNumber(e.target.value)}
          />
        </div>
        <div className='w-100 bg-white shadow-sm rounded-4 p-3 mt-3'>
          <h6 style={{ color: '#6c62ff' }}>PhonePe Number</h6>
          <input
            className='form-control mt-3'
            placeholder='PhonePe Number'
            value={phonePeNumber}
            onChange={(e) => setPhonePeNumber(e.target.value)}
          />
        </div>
        <div className='w-100 bg-white shadow-sm rounded-4 p-3 mt-3'>
          <h6 style={{ color: '#6c62ff' }}>Google Pay Number</h6>
          <input
            className='form-control mt-3'
            placeholder='Google Pay Number'
            value={googlePayNumber}
            onChange={(e) => setGooglePayNumber(e.target.value)}
          />
        </div>
        <button
          className='btn rounded-5 px-5 bg-white my-5 fw-bold'
          style={{ color: '#6c62ff' }}
          onClick={() => handleSubmit()}
        >
          SUBMIT
        </button>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default Bank;
