import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import { API_KEY } from '../utils/constants';
import { userDataAPI, withdrawAPI } from '../api/api';
import { toast } from 'react-toastify';
import Loader from '../components/loader';

function Withdraw() {
  const [amount, setAmount] = useState('');
  const [wallet, setWallet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleRequest = async () => {
    const userData = await localStorage.getItem('user');
    const user_uid = JSON.parse(userData).uid;

    if (Number(amount) < 999 || Number(amount) > 49000) {
      toast.error('You can withdraw in between 999 to 49000');
      return;
    } else if (Number(wallet) < Number(amount)) {
      toast.error('Insufficient funds!');
      return;
    } else {
      setIsLoading(true)
      const formData = new FormData();
      formData.append('__api_key__', API_KEY);
      formData.append('user_uid', user_uid);
      formData.append('amount', amount);

      await withdrawAPI(formData)
        .then((res) => {
          if (res.data.state === 'OK') {
            toast.success('You request has been successfull');
            setAmount('');
            setIsLoading(false)
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const getUserData = async () => {
    const userData = await localStorage.getItem('user');
    const user_uid = JSON.parse(userData)?.uid;

    const formData = new FormData();
    formData.append('__api_key__', API_KEY);
    formData.append('user_uid', user_uid);
    await userDataAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          setWallet(res.data.data.user_entity.wallet);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className='main-2' style={{ backgroundColor: '#6c62ff' }}>
      <Header title='Withdraw' isBack />
      <div className='container mt-3 d-flex flex-column align-items-center'>
        <div className='w-75 bg-white shadow-sm rounded-4 py-3 px-5 mt-3 text-center'>
          <h6 style={{ color: '#6c62ff' }}>Amount</h6>
          <input
            className='form-control mt-3'
            placeholder='Enter'
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <button
          className='btn rounded-5 px-4 bg-white mt-5 fw-bold'
          disabled={amount === ''}
          style={{ color: '#6c62ff' }}
          onClick={() => handleRequest()}
        >
          REQUEST WITHDRAW
        </button>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default Withdraw;
