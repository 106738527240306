import React, { useState } from 'react';

const OTPInput = ({ length, onChange }) => {
    const [otp, setOtp] = useState(new Array(length).fill(''));

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        const newOtp = [...otp.map((d, idx) => (idx === index ? element.value : d))];
        setOtp(newOtp);

        // Call the onChange callback if provided
        if (onChange) {
            onChange(newOtp.join(''));
        }

        // Focus next input
        if (element.value && element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleKeyDown = (element, index, event) => {
        if (event.key === 'Backspace' && !element.value) {
            // Focus previous input
            if (element.previousSibling) {
                element.previousSibling.focus();
            }
        }
    };

    return (
        <div>
            {otp.map((data, index) => (
                <input
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={e => handleChange(e.target, index)}
                    onKeyDown={e => handleKeyDown(e.target, index, e)}
                    onFocus={e => e.target.select()}
                />
            ))}
        </div>
    );
};

export default OTPInput;
