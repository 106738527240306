import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const HistoryCard = ({ item }) => {
  const navigate = useNavigate();
  const [gameData, setGameData] = useState([]);

  useEffect(() => {
    const fieldParams = Object.entries(item)
      .filter(([key]) => key.startsWith("field_"))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    const convertedArray = Object.entries(fieldParams).map(([key, value]) => (value != null ) &&({
      label: key.replace(/^field_/, '').padStart(2, '0'),
      amount: value,
    }));
    console.log("convertedArray",convertedArray);
    setGameData(convertedArray);
  }, []);
  return (
    <div className="bg-white rounded-3 p-3 shadow mt-2">
      <h5 className='fw-bold' style={{color: '#6c62ff'}}>{item.game_name}</h5>
      <h6 className="fw-bold" style={{color: '#6c62ff'}}>{item.game_type}</h6>
      <div className="flex-row d-flex justify-content-between">
        <label className="time fw-bold" style={{color: '#6c62ff'}}>DIGIT</label>
        <label className="time fw-bold">AMOUNT</label>
      </div>
      {gameData.map((game) => (
        <div className="flex-row d-flex justify-content-between">
          <label className="time">{game.label}</label>
          <label className="time">{game.amount}</label>
        </div>
      ))}
    </div>
  );
};

export default HistoryCard;
