import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import { API_KEY } from '../utils/constants';
import { userDataAPI } from '../api/api';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/loader';

function Pocketbook() {
  const navigate = useNavigate();
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const getUserData = async () => {
    const userData = await localStorage.getItem('user');
    const user_uid = JSON.parse(userData)?.uid;

    const formData = new FormData();
    formData.append('__api_key__', API_KEY);
    formData.append('user_uid', user_uid);
    await userDataAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          setUser(res.data.data.user_entity);
          setIsLoading(false)
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div className='main-2 bg-2'>
      <Header title='Pocketbook' isBack noWallet />
      <div className='container mx-auto d-flex flex-column align-items-center text-center'>
        <div className='bg-white shadow-sm rounded-4 px-5 pt-3 pb-4 mt-3'>
          <h6 style={{color: '#6c62ff'}}>Available</h6>
          <h1 className='m-0 fw-bold' style={{color: '#6c62ff'}}>{user.wallet}</h1>
        </div>
        <div className='d-flex justify-content-around flex-wrap'>
          <div className='col-5 bg-white shadow-sm rounded-4 p-3 mt-4' onClick={()=>navigate('/add')}>
            <img
              src='./images/add_funds.png'
              alt='add_funds'
              className='img-fluid w-75'
            />
            <h6 style={{color: '#6c62ff'}}>Add</h6>
          </div>
          <div className='col-5 bg-white shadow-sm rounded-4 p-3 mt-4' onClick={()=>navigate('/withdraw-history')}>
            <img
              src='./images/wallet_history.png'
              alt='add_funds'
              className='img-fluid w-75'
            />
            <h6 style={{color: '#6c62ff'}}>Withdraw History</h6>
          </div>
          <div className='col-5 bg-white shadow-sm rounded-4 p-3 mt-4' onClick={()=>navigate('/withdraw')}>
            <img
              src='./images/withdraw_points.png'
              alt='add_funds'
              className='img-fluid w-75'
            />
            <h6 style={{color: '#6c62ff'}}>Withdraw</h6>
          </div>
          <div className='col-5 bg-white shadow-sm rounded-4 p-3 mt-4' onClick={()=>navigate('/bank')}>
            <img
              src='./images/bank.png'
              alt='add_funds'
              className='img-fluid w-75'
            />
            <h6 style={{color: '#6c62ff'}}>Bank</h6>
          </div>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default Pocketbook;
