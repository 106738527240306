import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import { API_KEY } from '../utils/constants';
import { getWithdrawHistoryAPI } from '../api/api';
import Loader from '../components/loader';

function WithdrawHistory() {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getFundData = async () => {
    const userData = await localStorage.getItem('user');
    const user_uid = JSON.parse(userData).uid;

    const formData = new FormData();
    formData.append('__api_key__', API_KEY);
    formData.append('user_uid', user_uid);

    await getWithdrawHistoryAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          setHistory(res.data.data.meta);
          setIsLoading(false)
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getFundData();
  }, []);

  return (
    <div className='main-2 bg-4'>
      <Header title='Withdraw History' isBack />
      <div className='container mt-5'>
        {history.map((item, index) => (
          <div className='shadow-sm p-2 rounded-2 my-2'>
            <div className='d-flex justify-content-around'>
              <h5 style={{color: '#6c62ff'}}>{item.amount}</h5>
              <h6>{item.state}</h6>
            </div>
            <p className='m-0 text-center'>{item.created_at}</p>
          </div>
        ))}
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default WithdrawHistory;
